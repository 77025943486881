<template>
    <div id="order">
        <el-popover
            placement="top"
            width="380"
            v-model="visible">
            <p style="margin-bottom:20px">請選擇日期</p>
            <el-date-picker
                size="small"
                v-model="timeValue"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="margin-bottom:20px;">
            </el-date-picker>
            <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="importOut">确定</el-button>
            </div>
            <!-- <el-button slot="reference">删除</el-button> -->
            <div class="export-box" slot="reference">
                <span class="export">导出</span>
            </div>
        </el-popover>
        
        <ekk-fliter 
            placeholder="用户名/手机号"
            :noExport="true"
            :noDate="true"
            :statusList="filters.statusList"
            :status="status"
            @status="filters.status"
            :noStatus2="false"
            :status2="shop_id"
            :statusList2="filters.statusList2"
            @status2="filters.status2"
            @keyword="filters.search"
        />
        <ekk-table 
            :tableData="viewData.tableData"
            :options="viewData.options"
            :page="viewData.page"
            :feedback="viewData.feedback"
            @handle="viewData.handle"
            @handlePage="viewData.handlePage"
        />
        <transition name="slide-fade">
            <ekk-detail
                v-show="detail.show"
                :show="detail.show"
                :loading="detail.loading"
                :detailData="detail.detailData"
                @close="detail.close"
                @handleVideo="handleVideo"
            />
        </transition>
        <el-dialog 
        :title="dialogData.fromData.hasOwnProperty('products') && dialogData.fromData.videoIndex > -1 ? '' : `发起${dialogData.fromData.hasOwnProperty('products') ? '扣' : '退'}款`" 
        :visible.sync="dialogData.dialogVisible"
        :class="dialogData.fromData.videoIndex > -1 ? 'video-dialog' : 'normal-dialog'"
        >
            <div class="order-dialog">
                <div class="order-dialog-left"  v-if="dialogData.fromData.videoIndex > -1">
                    <iframe :src="dialogData.fromData.videoIndex > -1 ? dialogData.fromData.videoList[dialogData.fromData.videoIndex].url : ''" frameborder="0"></iframe>
                </div>
                <div class="order-dialog-right">
                    
                    <el-form 
                    :model="dialogData.fromData" 
                    :rules="dialogData.fromRules"
                    ref="fromData"
                    label-width="100px"
                    size="small">
                        <el-form-item label="视频" v-if="dialogData.fromData.videoIndex > -1">
                            <el-radio-group v-model="dialogData.fromData.videoIndex">
                                <el-radio-button v-for="(item, i) in dialogData.fromData.videoList" :key="i" size='small' :label="i" >{{item.name}}</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="扣款商品" prop="products"  v-if="dialogData.fromData.hasOwnProperty('products') ">
                            <el-autocomplete
                                v-model="searchGoodsKey"
                                :fetch-suggestions="querySearchAsync"
                                placeholder="请搜索商品"
                                @select="handleSelect"
                                ></el-autocomplete>
                            <el-table v-show="selectGoods.length > 0" :data="selectGoods" style="width: 100%" size='mini' max-height="250">
                                <el-table-column prop="product_name" label="商品名称"  :show-overflow-tooltip="true"></el-table-column>
                                <!-- <el-table-column prop="barcode" label="条形码"> </el-table-column> -->
                                <el-table-column prop="retail_price" label="单价" width="70" :show-overflow-tooltip="true">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.shops[0].retail_price | getPrice}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="retail_price" label="原价" width="70" :show-overflow-tooltip="true">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.shops[0].OriginalPrice | getPrice}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="count" label="数量" width="100">
                                    <template slot-scope="scope">
                                        <el-input-number size="mini" v-model="scope.row.count" :readOnly='true' controls-position="right" @change="handleChange" :min="1" :max="100" style="width: 80px"></el-input-number>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="retail_price" label="操作" width="45" align="center">
                                    <template slot-scope="scope">
                                        <el-button type="text" size="mini" @click="handleCancelGoods(scope.row.ID)"><i class="el-icon-close"></i></el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                        <el-form-item label="原价" prop="original_total_price">
                          <span>{{ dialogData.fromData.original_total_price }}元</span>
                        </el-form-item>
                        <el-form-item :label="`${dialogData.fromData.hasOwnProperty('products') ? '扣' : '退'}款金额`" prop="amount">
                            <el-input v-model="dialogData.fromData.amount" placeholder="请输入金额" style="width:200px;margin-right:10px"></el-input>
                            <span>元</span>
                        </el-form-item>
                       
                        <el-form-item label="当前活动" v-if="promotion.promotion_name">
                          <span>{{ promotion.promotion_name }} 折扣{{ promotion.discount }}%</span>
                        </el-form-item>
                    </el-form>
                    <div v-if="dialogData.fromData.videoIndex > -1" class="order-dialog-right-btn">
                        <el-button size="small" @click="dialogData.dialogVisible = false">取 消</el-button>
                        <el-button size="small" type="primary" @click="submitForm">确 定</el-button>
                    </div>
                </div>
            </div>
            
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogData.dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "@/utils/util";
import ekkTable from '@/components/ekk_table.vue'
import ekkFliter from '@/components/ekk_fliter.vue'
import ekkDetail from '@/components/ekk_detail.vue'

import { mapState,mapActions } from 'vuex';
import { ORDER_SHOP_ID, ORDER_STATUS } from '@/store/types';

export default {
    name:'order',
    components: { 
        ekkTable,
        ekkFliter,
        ekkDetail 
    },
    data(){
        return {
            shop_id:'',
            status:'',
            keyword:'',
            filters:{
                statusList: [
                    {id: '', name: '全部状态'},
                    {id: '0', name: '待支付'},
                    {id: '1', name: '支付成功'},
                    {id: '2', name: '支付失败'},
                    {id: '3', name: '订单取消'},
                    {id: '4', name: '订单过期'},
                    {id: '5', name: '支付中'},
                    {id: '6', name: '退款成功'},
                    {id: '7', name: '退款失败'},
                ],
                status: val => {
                    [this.status,this.viewData.page.cur] = [val, 1];
                    this[ORDER_STATUS](this.status);
                    this.getList()
                },
                statusList2: [
                    {id: '', name: '全部门店'},
                    // {id: 'W0001', name: 'W0001'},
                    // {id: 'W0002', name: 'W0002'},
                    // {id: 'W0003', name: 'W0003'},
                ],
                status2: val => {
                    [this.shop_id,this.viewData.page.cur] = [val, 1];
                    this[ORDER_SHOP_ID](this.shop_id);

                    this.getList()
                },
                // 关键字筛选
                search: val => {
                    setTimeout( ()=>{
                        [this.keyword,this.viewData.page.cur] = [val, 1];
                        this.getList();
                    },300)
                },
            },
            viewData: {
                options: [
                    {prop: "entered_at",label: '进店时间',width: 120,overflow: true,},
                    {prop: "leaved_at",label: '离店时间',width: 140,overflow: true,},
                    {prop: "count",label: '扫码',width: 40,overflow: true,},
                    {prop: "is_face",label: '刷脸',width: 40,overflow: true,},
                    {prop: "shop_name",label: '门店',width: 80,overflow: true,},
                    {prop: "out_order_no",label: '订单号',width: 130,overflow: true,},
                    {prop: "total",label: '订单金额',width: 70,overflow: true,},
                    {prop: "avatarUrl",label: '头像',width: 60,overflow: true,src:true,center:true},
                    {prop: "nickName",label: '微信昵称',width: 100,overflow: true,},
                    {prop: "phone",label: '手机号',width: 100,overflow: true,},
                    {prop: "pay_status",label: '状态',width: 60,overflow: true,},
                    {label: '操作', width: 120,center: true,slot: [
                        { type: "order", condition: 'pay_status', conditionList:{
                            '待支付':'扣款',
                        }},
                        { type: "close", condition: 'pay_status', conditionList:{
                            '待支付':'关闭',
                        }},
                        // { type: "refund", condition: 'can_refund', conditionList:{
                        //     'Y':'退款',
                        // }},
                        // { type:'video', condition: 'has_video_url',conditionList:{
                        //     'Y':'结算1',
                        // }},
                        // { type:'video2', condition: 'has_video_url2',conditionList:{
                        //     'Y':'结算2',
                        // }},
                        // { type:'video3', condition: 'has_video_url3',conditionList:{
                        //     'Y':'店内',
                        // }},
                        { type: "detail", name:'详情',},
                    ],},
                ],
                tableData: [],
                page: { cur: 1, size: 20, total: 0, },
                feedback: "",
                // 列表操作
                handle: (row, type) => {
                    this.dialogData.fromData = {
                        "openid":row.user.openid,
                        "out_order_no":row.out_order_no,
                        "amount":"",
                    };
                    switch (type) {
                        case "detail":
                            this.handleDetail(row);
                            break;
                        case "order":
                            this.handleOrder(row);
                            break;
                        case "close":
                            this.handleClose();
                            break;
                        case 'refund':
                            this.dialogData.fromData.amount = row.total.replace('¥','')
                            this.dialogData.dialogVisible = true;
                            break;
                        case "video":
                            window.open(row.video_url);
                            break;
                        case "video2":
                            window.open(row.video_url2);
                            break;
                        case "video3":
                            window.open(row.video_url3);
                            break;
                        default:
                            break;
                    }
                },
                // 页码切换
                handlePage: (val) => {
                    this.viewData.page.cur = val;
                    this.getList();
                },
            },
            detail: {
                show: false,
                loading: false,
                detailData: {
                    title: '订单管理-详情',
                    top:{
                        img:'',
                        title:''
                    },
                    infos: [
                        {title: '用户信息', info: [
                            {name: '微信昵称', val: ''},
                            {name: '手机号', val: ''},
                            {name: '进店时间', val: ''},
                            {name: '离店时间', val: ''},
                        ]},
                        {title: '订单信息', info: [
                            {name: '门店', val: ''},
                            {name: '扫码次数', val: ''},
                            {name: '是否刷脸', val: ''},
                            {name: '订单号', val: ''},
                            {name: '订单金额', val: ''},
                            {name: '订单状态', val: ''},
                        ]},
                    ]
                },
                // 关闭详情
                close: () => {
                    this.detail.show = false
                }
            },
            dialogData:{
                dialogVisible:false,
                fromData:{
                    "openid":"",
                    "out_order_no":"",
                    "amount":"",
                },
                fromRules: {
                    amount: [
                        { required: true, message: '请输入金额', trigger: 'blur' },
                    ],
                },
            },
            loading:false,
            searchGoodsKey:'',
            goodsList:[],
            promotion: {
              promotion_name: '',
              discount: 0,
            },
            selectGoods:[],
            visible:false,
            timeValue:''
        }
    },
    computed: {
        ...mapState({
            order_shop_id: state => state.order.shop_id,
            order_status: state => state.order.status
        })
    },
    watch:{
        timeValue(val){
            console.log(val)
        },
        selectGoods:{
            handler(val){
                this.dialogData.fromData.products = val.map(v=> ({product_id: v.ID, count: v.count, price:v.shops[0].retail_price, original_price: v.shops[0].OriginalPrice}))
                this.dialogData.fromData.amount = util.getPrice(this.dialogData.fromData.products.reduce((prev, current, currentIndex, arr) => {
                    return prev+(current.price * current.count)
                }, 0)).replace('¥','')
                this.dialogData.fromData.original_total_price = util.getPrice(this.dialogData.fromData.products.reduce((prev, current, currentIndex, arr) => {
                    return prev+(current.original_price * current.count)
                }, 0)).replace('¥','')
            },
            deep:true
        }
    },
    filters:{
        getPrice(val){
            return util.getPrice(val)
        }
    },
    created(){
        this.shop_id = this.order_shop_id;
        this.status = this.order_status;
        this.getShopList();
        this.getList();

    },
    methods:{
        ...mapActions([ORDER_SHOP_ID, ORDER_STATUS]),
        // 获取门店列表
        getShopList(){
            this.$http.get('smart_shops',{
                params:{}
            }).then( res =>{
                let _list = res.map(v=> ({id: v.shop_id, name: v.shop_name}))
                this.filters.statusList2.push.apply(this.filters.statusList2, _list)
            }).catch( err =>{
            })
        },
        //获取列表
        getList(){
            this.viewData.feedback = 'loading';
            this.viewData.tableData = [];

            this.$http.get('orders',{
                params:{
                    shop_id:this.shop_id,
                    status:this.status,
                    search:this.keyword,
                    page:this.viewData.page.cur,
                    pagesize:this.viewData.page.size,
                }
            }).then( res =>{
                let {lists,total} = res;
                let _listCopy = [];
                if(lists.length>0){
                    lists.forEach((item, index) => {
                        _listCopy.push({
                            ...item,
                            'total':item.pay_status == 0 ? '' : util.getPrice(item.total),
                            'avatarUrl':item.user.avatarUrl,
                            'nickName':item.user.nickName,
                            'phone':item.user.phone,
                            'pay_status':util.getPayStatus(item.pay_status),
                            'created_at': util.getLocalTime({
                                data:util.getRequestTime(item.created_at) 
                            }),
                            'is_face':item.is_face == 1?"是":"否",
                            'count':item.count,
                            'leaved_at': item.leaved_at !='0001-01-01T00:00:00Z'?util.getLocalTime({
                                data:util.getRequestTime(item.leaved_at) 
                            }):'',
                            'entered_at': item.entered_at !='0001-01-01T00:00:00Z'?util.getLocalTime({
                                data:util.getRequestTime(item.entered_at) 
                            }):'',
                            
                            'shop_name':item.shop.shop_name,
                            'can_refund': util.getPayStatus(item.pay_status) == '支付成功' && !item.refund_order.ID ? 'Y' : 'N',
                            'has_video_url':item.video_url != '' ? 'Y' : 'N',
                            'has_video_url2':item.video_url2 != '' ? 'Y' : 'N',
                            'has_video_url3':item.video_url3 != '' ? 'Y' : 'N'
                        });
                    });
                    this.viewData.feedback = '';
                }else{
                    this.viewData.feedback = 'empty';
                }
                this.viewData.tableData = _listCopy;
                this.viewData.page.total = total;
            }).catch( err =>{
                this.viewData.feedback = 'error';
            })
        },
        // 店铺下搜索商品
        handleSearchGoods(){
            // /search_shop_products/{shop_id}
        },
        remoteMethod(query) {
            if(query == '') return;
            const _shopId = this.dialogData.fromData.row.shop_id;
            const _out_order_no = this.dislogData.fromData.out_order_no;
            this.$http.get(`search_shop_products/${_shopId}/${_out_order_no}`,{
                params:{
                    shop_id:_shopId,
                    search:query,
                }
            }).then( res =>{
                this.goodsList = res.lists;
                this.promotion = res.promotion;
            }).catch( err =>{
            })
        },
        querySearchAsync(queryString, cb) {
            if(queryString == '') return;
            const _shopId = this.dialogData.fromData.row.shop_id;
            const _out_order_no = this.dialogData.fromData.row.out_order_no;
            this.$http.get(`search_shop_products/${_shopId}/${_out_order_no}`,{
                params:{
                    shop_id:_shopId,
                    search:queryString,
                }
            }).then( res =>{
                this.goodsList = res.lists.map(v=>({...v,'value':v.product_name}));
                this.promotion = res.promotion;
                cb(this.goodsList)
            }).catch( err =>{
                cb([])
            })
        },
        handleSelect(item) {
            this.selectGoods.push({
                ...item,
                'count':1
            })
        },
        handleChange(value) {
            // console.log(111,value);
        },
        handleCancelGoods(produceId){
            let _index = this.selectGoods.findIndex(v => v.ID == produceId);
            this.selectGoods.splice(_index,1)
        },
        // 发起扣款
        submitForm() {
            this.$refs['fromData'].validate((valid) => {
            if (valid) {
                // /complete_order/:openid/:out_order_no?amount=1
                let { openid,out_order_no,amount, products, original_total_price} = this.dialogData.fromData;
                const _isOrder = this.dialogData.fromData.hasOwnProperty('products');
                if(Number(amount) <= 0){
                    this.$message({
                        type: "error",
                        duration: 2000,
                        message: `${_isOrder ? '扣' : '退'}款金额需大于0！`,
                    });
                    return;
                }
                if( _isOrder && products.length > 0){
                    let _amount = products.reduce((prev, current) => {
                        return prev+(current.price * current.count)
                    }, 0);
                    if(_amount != (amount * 100).toFixed(0)){
                        this.$message({
                            type: "error",
                            duration: 2000,
                            message: '扣款金额不等于所有商品价格，请重新核对',
                        });
                        return;
                    }
                    
                }
                if(_isOrder){
                    this.$http.post(`complete_order_product/${openid}/${out_order_no}`,{
                        amount:Number((amount * 100).toFixed(0)),
                        original_total_price:Number((original_total_price * 100).toFixed(0)),
                        products: products,
                        out_order_no: out_order_no
                    }).then( res =>{
                        this.$message({
                            type: "success",
                            duration: 2000,
                            message: '扣款成功！',
                        });
                        this.dialogData.dialogVisible = false;
                        this.getList();
                    }).catch( err =>{
                        this.$message({
                            type: "error",
                            duration: 2000,
                            message: '扣款失败，请重试！',
                        });
                    })
                }else{
                    this.$http.get(`refund_order/${openid}/${out_order_no}`,{
                        params:{
                            amount:Number((amount * 100).toFixed(0)),
                        }
                    }).then( res =>{
                        this.$message({
                            type: "success",
                            duration: 2000,
                            message: '退款成功！',
                        });
                        this.dialogData.dialogVisible = false;
                        this.getList();
                    }).catch( err =>{
                        this.$message({
                            type: "error",
                            duration: 2000,
                            message: '退款失败，请重试！',
                        });
                    })
                }
                
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        resetForm(formName) {
            if(this.$refs[formName]){
                this.$refs[formName].resetFields();
            }
        },
        // 关闭订单
        handleClose() {
            let { openid,out_order_no} = this.dialogData.fromData;

			this.$confirm(`确定要关闭订单${out_order_no}吗？`,'提示',{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: "warning",
			}).then(() => {
                
                this.$http.get(`cancel_order/${openid}/${out_order_no}`,{
                    params:{}
                }).then( res =>{
                    this.$message({
                        type: "success",
                        duration: 2000,
                        message: '关单成功！',
                    });
                    this.getList();
                }).catch( err =>{
                    this.$message({
                        type: "error",
                        duration: 2000,
                        message: '关单失败，请重试！',
                    });
                })
                
			}).catch(() => { });
		},
        // 导出 
        importOut() {
            this.visible = false;
            let start_time,end_time;
            if(this.timeValue){
                start_time = this.timeValue[0].getTime()/1000;
                end_time = this.timeValue[1].getTime()/1000 + 60*60*24 - 1;
            }else{
                start_time = '';
                end_time = '';
            }
            let url = this.$http.defaults.baseURL + 
                `orders/export?shop_id=${this.shop_id}&begin=${start_time}&end=${end_time}`;
            window.open(url)
        },
        handleDetail(row){
            console.log(row)
            let _videoList = [];
            if(row.has_video_url == 'Y'){
                _videoList.push({
                    name:'结算1',
                    url:row.video_url,
                })
            }
            if(row.has_video_url2 == 'Y'){
                _videoList.push({
                    name:'结算2',
                    url:row.video_url2,
                })
            }
            if(row.has_video_url3 == 'Y'){
                _videoList.push({
                    name:'店内',
                    url:row.video_url3,
                })
            }

            this.detail.show = true;
            this.dialogData.fromData = {
                "openid":row.user.openid,
                "out_order_no":row.out_order_no,
                "amount":"",
            };
            this.detail.detailData = {
                title: '订单管理-详情',
                top:{
                    img:row.avatarUrl,
                    title:row.nickName
                },
                infos: [
                    {title: '基本信息', info: [
                        {name: '微信昵称', val: row.nickName},
                        {name: '手机号', val: row.phone},
                        {name: '进店时间', val: row.entered_at},
                        {name: '离店时间', val: row.leaved_at},
                        {name: '门店ID', val: row.shop_id},
                        {name: '门店名', val: row.shop?.shop_name},
                        {name: '扫码次数', val: row.count},
                        {name: '是否刷脸', val: row.is_face},
                        {name: '店內视频',video:_videoList}
                    ]},
                    {title: '订单信息', info: 
                        row.pay_status == '待支付' ? [
                            {name: '订单号', val: row.out_order_no},
                            {name: '订单状态', val: row.pay_status},
                        ]: row.refund_order.ID ? [
                            {name: '订单号', val: row.out_order_no},
                            {name: '订单状态', val: row.pay_status},
                            {name: '订单金额', val: row.total},
                            {name:'支付时间',val:row.payed_at !='0001-01-01T00:00:00Z'?util.getLocalTime({
                                    data:util.getRequestTime(row.payed_at) }):''},
                            {name: '退款时间', val: row.refund_order.payed_at !='0001-01-01T00:00:00Z'?util.getLocalTime({
                                data:util.getRequestTime(row.refund_order.payed_at) }):''} ,
                            {name: '退款金额', val: util.getPrice(row.refund_order.total)} ,
                        ]:[
                            {name: '订单号', val: row.out_order_no},
                            {name: '订单状态', val: row.pay_status},
                            {name: '订单金额', val: row.total},
                            {name: '支付时间',val:row.payed_at !='0001-01-01T00:00:00Z'?util.getLocalTime({
                                    data:util.getRequestTime(row.payed_at) }):''},
                        ]
                    },
                ],
                table:row.order_products.length > 0 ? {
                    tab:['扣款商品'],
                    tableData:row.order_products.map(v=>({...v,retail_price:util.getPrice(v.retail_price)})),
                    options: [
                        {prop: 'product_name', label: '商品名称', overflow: true, width: 160},
                        {prop: 'retail_price', label: '售价', overflow: true},
                        {prop: 'count', label: '数量', overflow: true},
                    ],
                    feedback: '', 
                }: null,
                btns: row.pay_status == '待支付' ? [
                    {btnName:'扣款', btnEvent:()=>{
                        this.handleOrder(row);
                    }},
                    {btnName:'关闭', btnEvent:()=>{
                        this.handleClose();
                    }},
                ] : row.can_refund == 'Y' ? [
                    {btnName:'退款', btnEvent:()=>{
                        this.dialogData.fromData.amount = row.total.replace('¥','')
                        this.dialogData.dialogVisible = true;
                    }},
                ] : null,
            }
        },
        handleOrder(row){
            this.loading = false;
            this.searchGoodsKey = '';
            this.goodsList = [];
            this.selectGoods = [];
            this.dialogData.dialogVisible = true;
            this.promotion = {
              promotion_name: '',
              discount: 0,
            };
            let _videoList = [];
            if(row.has_video_url == 'Y'){
                _videoList.push({
                    name:'结算1',
                    url:row.video_url,
                })
            }
            if(row.has_video_url2 == 'Y'){
                _videoList.push({
                    name:'结算2',
                    url:row.video_url2,
                })
            }
            if(row.has_video_url3 == 'Y'){
                _videoList.push({
                    name:'店内',
                    url:row.video_url3,
                })
            }
            this.dialogData.fromData = {
                ...this.dialogData.fromData,
                "products":[],
                // product_id 商品ID
                // count数量
                // price單價
                "videoList":_videoList,
                "videoIndex":_videoList.length>0 ? 0 : -1,
                "row":row
            }
        },
        handleVideo(v){
            console.log(v)
            window.open(v.url);
        }
    }
}
</script>

<style lang="scss" scoped>
.video-dialog{
    ::v-deep .el-dialog{
        width: 1300px;
        .el-dialog__header{
            display: none;
        }
        .el-dialog__body{
            padding: 0;
        }
        .el-dialog__footer{
            display: none;
        }
    }
    .order-dialog{
        display: flex;
        height: 650px;
        .order-dialog-left{
            // width: 70%;
            width: 910px;
            iframe{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .order-dialog-right{
            height: 100%;
            min-height: 650px;
            padding: 40px 20px 20px 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            .order-dialog-right-btn{
                width: 100%;
                text-align: center;
                margin-left: 40px;
            }
        }
    }
}
.normal-dialog{
    ::v-deep .el-dialog{
        width: 500px;
        
    }
    .order-dialog{
        display: flex;
        .order-dialog-left{
            width: 65%;
            min-height: 350px;
            iframe{
                width: 100%;
                // height: 100%;
                height: 500px;
            }
        }
        .order-dialog-right{
            // width: 50%;
        }
    }
}

</style>